// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import FeaturedPost from "../components/FeaturedPost";
import { blogList, blogListPreview } from "../styles/blog-list.module.scss";
import styles from "../styles/tags-post.module.scss";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges: posts, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } com a tag "${tag}"`;
  return (
    <Layout header={tagHeader}>
      <div className={styles.container}>
        <div className={blogList}>
          {posts.map(({ node: post }) => {
            return (
              <FeaturedPost
                post={post}
                className={blogListPreview}
                key={post.id}
              />
            );
          })}
        </div>
        <Link to="/tags">Todas as tags</Link>
      </div>
    </Layout>
  );
};

export const tagsQuery = graphql`
  query BlogPostByTag($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Tags;
