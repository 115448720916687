import React from "react";
import classnames from "classnames";
import Img from "gatsby-image";
import { Link } from "gatsby";
import style from "./FeaturedPost.module.scss";

const FeaturedPost = ({ post, className }) => (
  <article className={classnames(style.postPreview, className)}>
    <div className={style.postPreviewBackground}>
      <Img
        className={style.postPreviewBackgroundImg}
        fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
      />
    </div>
    <Link to={post.frontmatter.path} className={style.link}>
      <div className={style.postPreviewContent}>
        <h1 className={style.postPreviewContentTitle}>
          {post.frontmatter.title}
        </h1>
        <div className={style.postPreviewContentDate}>
          {post.frontmatter.date}
        </div>
        {!!post.frontmatter.category && (
          <div className={style.postPreviewContentCategory}>
            {post.frontmatter.category}
          </div>
        )}
        <div className={style.postPreviewContentSummary}>{post.excerpt}</div>
      </div>
    </Link>
  </article>
);

export default FeaturedPost;
